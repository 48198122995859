body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Basic reset */


/* Body styling */


/* Director's Message Section */
.director-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #ede6e6;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Container inside the section */
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    flex-wrap: wrap;
}

/* Image styling */
.image {
    flex: 1;
    margin-right: 20px;
}

.image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Message styling */
.message {
    flex: 2;
}

.message h1 {
    margin-bottom: 10px;
    font-size: 24px;
}

.message p {
    font-size: 16px;
    color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .image {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
